<template>

  <v-container
    fluid
    class="ma-0 pa-0"
    >

    <div
      class="ml-sm-4"
      v-if="this.$vuetify.breakpoint.mdAndUp || this.$route.name == 'channels'"
      :style="$vuetify.breakpoint.mdAndUp
        ? 'display: grid; grid-template-columns: 300px auto;'
        : 'position: absolute; left: 0; bottom: 0; top: 0; right: 0; display: grid; grid-template-columns: auto;'"
      >

      <div style="grid-column: 1;">

        <div
          :style="$vuetify.breakpoint.mdAndUp
            ? 'width: 300px; position: sticky; overflow-y: scroll;'
            : ''
            ">

          <v-list
            v-model="channelSelectedIndex">

            <v-list-item class="pt-0 px-2">
              <NewChannelDialog />

              <v-spacer />

            </v-list-item>

            <v-list-item class="pa-0">
              <v-text-field
                dense
                hide-details
                outlined
                clearable
                label="Name, Address, Email, Phone"
                v-model="channelFilter"
                @click:clear="clearChannelFilter"
                class="mt-2" />
            </v-list-item>

            <v-list-item
              v-for="item in filteredChannelList"
              :key="item.id"
              link
              :to="{name: 'channel', params: { channel_id: item.id }}">

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <template v-if="item.country">
                    {{ countryName(item.country) }}
                  </template>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <template v-if="item.networks_count">
                  Networks {{ item.networks_count }}
                  </template>
                  <template v-else>
                    <em>No Networks</em>
                  </template>

                  &middot;

                  <template v-if="item.terminals_count">
                  Terminals {{ item.terminals_count }}
                  </template>
                  <template v-else>
                    <em>No Terminals</em>
                  </template>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-icon class="pr-2">
                <v-icon :style="{visibility: item.active ? 'hidden' : 'visible'}" color="red" >mdi-minus-circle-outline</v-icon>

                <v-icon>mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>

        </div>
      </div>

      <div
        class="mt-sm-1 ml-sm-1"
        v-if="$vuetify.breakpoint.mdAndUp"
        style="grid-column: 2; overflow: scroll;"
        >
        <router-view />
      </div>
    </div>

    <router-view v-else />

  </v-container>

</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import PhoneNumber from 'awesome-phonenumber'
import NewChannelDialog from '@/components/NewChannelDialog.vue'
import ct from 'countries-and-timezones'

export default {
  data() {
    return {
      channelFilter: '',
      channelSelectedIndex: undefined,
      channelSelected: 0,
      filteredChannelList: [],
      channelId: undefined,

      newChannelDialog: false,
    }
  },

  metaInfo() {
    return {
      title: 'Channels',
    }
  },

  computed: {
    ...mapGetters('Channel', ['channels'])
  },

  components: {
    NewChannelDialog,
  },

  methods: {
    ...mapActions('Channel', [ 'getChannelList', 'getNetworkList' ]),

    countryName(code) {
      return ct.getCountry(code)?.name
    },

    formatPhone: (phone) => phone ? PhoneNumber(phone.replace(/\D/gi, ''), 'US').getNumber('national') : undefined,

    clearChannelFilter() {
      this.channelFilter = ''
    },

    filterChannelList() {
      const filter = this.channelFilter?.toLowerCase() ?? ''
      const channels = this.channels

      const filteredChannelList = filter.length == 0
        ? channels
        : channels.filter(channel =>
            channel.name?.toLowerCase().includes(filter) ||
            channel.address?.toLowerCase().includes(filter) ||
            channel.city?.toLowerCase().includes(filter) ||
            channel.zip?.toLowerCase().includes(filter) ||
            channel.country?.toLowerCase().includes(filter) ||
            channel.phone?.toLowerCase().includes(filter) ||
            channel.email?.toLowerCase().includes(filter)
          )

      this.filteredChannelList = [...filteredChannelList]
    },
  },

  mounted() {
    this.getChannelList()
    this.getNetworkList()
  },

  watch: {

    channels: {
      handler() {
        this.filterChannelList()
      },
      immediate: true,
    },

    channelFilter: {
      handler() { this.filterChannelList() },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
/*::v-deep {
  .v-badge--inline .v-badge__badge {
    background-color: inherit !important;
    width: 0.7em;

    &:after {
    border-color: gray;
  }
  }
}
*/
</style>

